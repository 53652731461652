import React from 'react';

const About = () => {
  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="omoss">
      <p className="intro-text">Välkommen till din lokala verkstad!</p>
      <p>Vi utför alla typer av reparationer och service för att hålla din bil i toppskick.</p>
      <button className="kontakta-btn" onClick={scrollToFooter}>
        Kontakta oss så hjälper vi dig!
      </button>
    </div>
  );
};

export default About;
