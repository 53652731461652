import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import ProductList from './components/ProductList';
import Footer from './components/Footer';
import ProductDetail from './components/ProductDetail';
import About from './components/About';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <div className="about-contact-container">
          <About />
          
          <div className="kontakt-info">
            <h4>Kontakt</h4>
            <p>E-post: <a href="mailto:ssfordon@outlook.com">ssfordon@outlook.com</a></p>
            <p>Telefon: <a href="tel:+46739747700">+46 73 974 77 00</a></p>
            <p>Adress: Brådstupsvägen 9, 129 39 Hägersten, Sverige</p>

            <div className="map-container">
              <iframe
                title="Super Sport Fordon"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2008.5274283893468!2d17.98678541583948!3d59.29061998164743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f754a1b12c3e7%3A0xd9f8e3f9d0b8f6c7!2sBr%C3%A5dstupsv%C3%A4gen%209%2C%20129%2039%20H%C3%A4gersten%2C%20Sverige!5e0!3m2!1ssv!2sse!4v1618406594000!5m2!1ssv!2sse"
                width="100%"
                height="200"
                style={{ border: 0, borderRadius: '8px' }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>

        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/produkt/:productId" element={<ProductDetail />} />
          <Route path="/omoss" element={<About />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
