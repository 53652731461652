import React from 'react';
import { useParams } from 'react-router-dom';

// Samma produktdata som i ProductList.js
const produkter = [
  {
    id: 1,
    name: 'Bromssystem',
    description: 'Högkvalitativ bromsskiva för pålitlig bromsning',
    image: '/images/bromsskiva.png'
  },
  {
    id: 2,
    name: 'Service och Oljebyte ',
    description: 'Vi utför service och oljebyte',
    image: '/images/olja.png'
  },
  {
    id: 3,
    name: 'Avgassystem',
    description: 'Effektivt avgassystem för förbättrad prestanda',
    image: '/images/avgas.png'
  }
];

const ProductDetail = () => {
  const { productId } = useParams();
  const produkt = produkter.find((p) => p.id === parseInt(productId)); // Hitta produkten

  if (!produkt) {
    return <p>Produkten kunde inte hittas.</p>;
  }

  return (
    <div className="produktdetalj">
      <h2>{produkt.name}</h2>
      <img 
        src={produkt.image} 
        alt={produkt.name} 
        style={{ width: '200px', height: 'auto', borderRadius: '8px', margin: '10px 0' }} 
      />
      <p>{produkt.description}</p>
      <button onClick={() => window.history.back()}>Gå tillbaka</button>
    </div>
  );
};

export default ProductDetail;
