import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({ produkt }) => {
  return (
    <div className="produktkort">
      <img src={produkt.image} alt={produkt.name} className="produktbild" />
      <h3>{produkt.name}</h3>
      <p>{produkt.description}</p>
      <Link to={`/produkt/${produkt.id}`}>
        <button>Visa detaljer</button>
      </Link>
    </div>
  );
};

export default ProductCard;
