import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <h1>Välkommen till Super Sport Fordon</h1>
      <nav>
        <ul>
          <li><Link to="/">Hem</Link></li>
          <li><Link to="/omoss">Om oss</Link></li>
          <li><Link to="/kontakt">Kontakt</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
