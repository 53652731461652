import React from 'react';
import ProductCard from './ProductCard';

const produkter = [
  {
    id: 1,
    name: 'Bromssystem',
    description: 'Vi gör bromsskivebyte och dylikt arbete',
    image: '/images/bromsskiva.png'
  },
  {
    id: 2,
    name: 'Service och Oljebyte ',
    description: 'Komplett set för regelbundet oljebyte',
    image: '/images/olja.png'
  },
  {
    id: 3,
    name: 'Avgassystem och motorreparation',
    description: 'Reparation av avgassystem och motorer',
    image: '/images/avgas.png'
  },
  {
    id: 4,
    name: 'Övrigt',
    description: 'Diverse reparationer och tillbehör för olika behov',
    image: '/images/ovrigt.png'
  }
];


const ProductList = () => {
  return (
    <section id="produkter">
      <div className="produktlista">
        {produkter.map((produkt) => (
          <ProductCard key={produkt.id} produkt={produkt} />
        ))}
      </div>
    </section>
  );
};

export default ProductList;
